import React, { useState } from 'react';
import './style.css'; // Pastikan untuk membuat file CSS ini
import product1 from '../../../assets/product1.jpg';
import product2 from '../../../assets/product2.jpg';
import product3 from '../../../assets/product3.jpg';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const images = [
    product1,
    product2,
    product3,
];

const products = [
    {
        name: "Produk 1",
        details: "Detail singkat produk 1",
        link: "link_ke_produk_1"
    },
    {
        name: "Produk 2",
        details: "Detail singkat produk 2",
        link: "link_ke_produk_2"
    },
    {
        name: "Produk 3",
        details: "Detail singkat produk 3",
        link: "link_ke_produk_3"
    }
];

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="grid grid-cols-5">
            <div className="carousel col-span-3">
                <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {images.map((image, index) => (
                        <div className={`carousel-item ${index === currentIndex ? 'active' : ''}`} key={index}>
                            <img src={image} alt={`Gambar ${index + 1}`} className='w-full max-w-md h-auto mx-auto' />
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-span-2 flex flex-col w-full items-start justify-center pl-12">
                <div className="title">
                    <ul>
                        <li>
                            <div className="">
                                <h1>{products[currentIndex].name}</h1>
                                <p>{products[currentIndex].link}</p>
                            </div>
                        </li><br />
                        <li>
                            <div className="">
                                <h1>Detail</h1>
                                <p>{products[currentIndex].details}</p>
                            </div>
                        </li><br />
                        <li>
                            <div className="">
                                <h1>Link</h1>
                                <p>{products[currentIndex].link}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="">
                    <ArrowLeft onClick={prevSlide} sx={{ fontSize: 100 }} />
                    <ArrowRight onClick={nextSlide} sx={{ fontSize: 100 }} />
                </div>
            </div>
        </div>
    );
};

export default Carousel;
