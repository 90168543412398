import React, { useState, useEffect } from 'react';
import { Menu } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import Logo from '../../../assets/logo_short.svg';
import TextLogo from '../../../assets/Group 10.svg';
import LogoComponent from '../../atoms/LogoComponent';

const Navbar = ({ isDarkMode, scrollToSection }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
        if (window.innerWidth >= 900) {
            setIsMenuOpen(false); // Menutup menu saat berpindah ke layar lebih besar
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleDrawer = (open) => () => {
        setIsMenuOpen(open);
    };

    const menuItems = ['beranda', 'layanan', 'program', 'kontak'].map(section => (
        <p key={section} onClick={() => { scrollToSection(section); setIsMenuOpen(false); }} className="navbar-item">
            {section.charAt(0).toUpperCase() + section.slice(1)}
        </p>
    ));

    return (
        <div className={`flex flex-row justify-between items-center sticky w-full z-50 top-0 p-2 px-12 ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'}`}>
            <LogoComponent logoSrc={Logo} textLogoSrc={TextLogo} altText={'Edunex'} />
            {isMobile ? (
                <div className="flex w-full items-center justify-end ">
                    <IconButton onClick={toggleDrawer(true)}>
                        <Menu style={{ color: isDarkMode ? 'white' : 'black'}} />
                    </IconButton>
                </div>
            ) : (
                <div className="flex w-full gap-x-12 text-xl font-semibold items-center justify-end cursor-pointer px-4 bg-inherit">
                    {menuItems}
                </div>
            )}
            <Drawer anchor="right" open={isMenuOpen} onClose={toggleDrawer(false)}>
                <div className={`flex flex-col w-[250px] h-full p-4 ${isDarkMode ? 'bg-slate-800 text-white' : 'bg-slate-200'}`}>
                    {menuItems}
                </div>
            </Drawer>
        </div>
    );
};

export default Navbar;
