import React, { useState, useEffect } from 'react';
import Logo from '../../assets/logo_short.svg';
import TextLogo from '../../assets/Group 10.svg';
import home from '../../assets/main.jpg';
import product1 from '../../assets/product1.jpg';
import product2 from '../../assets/product2.jpg';
import product3 from '../../assets/product3.jpg';
import './style.css';
import { ArrowLeft, ArrowRight, DarkMode, Facebook, Instagram, Mail, PhoneAndroid, Twitter, WbSunny } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Carousel from '../../components/molecules/Carousel';

const LandingPage = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDarkMode = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    const scrollToSection = (id) => {
        if (id === 'home') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            const element = document.getElementById(id);
            if (element) {
                const elementRect = element.getBoundingClientRect();
                const offset = -250; // Offset vertikal dalam piksel
                const elementTop = elementRect.top + window.pageYOffset;
                const scrollToPosition = elementTop + offset;

                window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className={`Header w-full h-full ${isDarkMode ? 'dark-mode' : ''}`}>
            {/* HEADER + NAVIGATION */}
            <div className={`flex sticky w-full z-50 top-0 p-2 ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'}`}>
                <div className='flex w-full items-center py-4 gap-6'>
                    <img src={Logo} alt="black logo" className="size-[70px]" />
                    <img src={TextLogo} alt={''} className="w-[120px] h-auto" />
                </div>
                <div className="flex w-full gap-x-6 text-lg font-semibold items-center justify-end cursor-pointer px-4">
                    <p onClick={() => scrollToSection('home')} className="navbar-item">Beranda</p>
                    <p onClick={() => scrollToSection('projects')} className="navbar-item">Layanan</p>
                    <p onClick={() => scrollToSection('about')} className="navbar-item">Program</p>
                    <p onClick={() => scrollToSection('contact')} className="navbar-item">Tentang Kami</p>
                </div>
                <button onClick={toggleDarkMode} className="toggle-button">
                    {isDarkMode ? <WbSunny /> : <DarkMode />}
                </button>
            </div>

            {/* HEAD CONTENT */}
            <div className="flex flex-col text-justify">
                <div id="home" className="grid grid-cols-5 gap-8 px-4 pt-8 py-12 text-lg items-start justify-center w-full">
                    <div className="content flex flex-col col-span-2 gap-6 place-content-start">
                        {/* <h1 className="font-bold text-3xl mb-4 pt-4">Transformasi Pendidikan Sekolah dengan Absensi Digital dan Pelajaran Online</h1> */}
                        <h1 className='font-bold mb-4 text-8xl pt-4 opacity-35 text-left'>Who We Are?</h1>
                        <p>Transformasi digital di pendidikan kini lebih mudah dari sebelumnya! Dengan sistem absensi online kami, catat dan lacak kehadiran siswa tanpa kerumitan. Ditambah dengan platform pelajaran online kami yang dirancang untuk interaktivitas dan keterlibatan siswa, Anda dapat memodernisasi cara Anda mengelola sekolah dan mengajarkan materi pelajaran. Ciptakan lingkungan belajar yang lebih adaptif dan efisien hari ini!</p>
                        <div className="w-fit h-auto font-bold">
                            <button className='w-auto h-auto px-6 py-2 bg-orange-500 hover:bg-orange-600 rounded text-white'>Contact Us</button>
                        </div>
                    </div>
                    <div className="w-full h-full col-span-3 flex items-center justify-center">
                        <img id='image' src={home} alt="homepage" className="w-1/2" />
                    </div>
                </div>

                {/* BODY CONTENT */}
                <div className={`w-full h-fit pt-24 py-12 flex flex-col ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'} justify-center`}>
                    <div className="flex justify-center pt-24">
                        <h1 className='font-bold mb-4 text-8xl pt-4 opacity-35'>Layanan</h1>
                    </div>
                    <div id="projects" className='grid grid-cols-2 gap-8 px-4 py-12 text-lg items-start justify-center'>
                        <div className='content flex flex-col'>
                            <h1 className='font-semibold text-3xl underline pb-6'>Transform School Administration: Ultimate Online Attendance & Interactive Learning Solutions</h1>
                            <p>
                                Revolutionize your school's administration with our cutting-edge online attendance and interactive learning platforms. Seamlessly track attendance and engage students like never before. Transform education effortlessly with our innovative solutions!
                            </p>
                        </div>
                        <div className="w-full flex items-center justify-center">
                            <img id='image' src={product1} alt='product1' className="w-3/4" />
                        </div>
                    </div>
                </div>

                <div className="flex justify-center pt-24">
                    <h1 className='font-bold mb-4 text-8xl pt-4 opacity-35'>Program</h1>
                </div>
                <div id="about" className='content flex gap-8 px-4 py-12 text-lg items-start justify-center'>
                    {/* <div className="w-full col-span-3 flex items-center justify-center">
                        <img id='image' src={product2} alt='product2' className="w-3/4" />
                    </div>
                    <div className='content col-span-1 col-start-5 flex flex-col items-start justify-center h-full'>
                        <div className="">
                            <h1>Produk</h1>
                            <h1>Detail</h1>
                            <h1>Link</h1>
                        </div>
                        <div className="flex gap-6">
                            <IconButton>
                                <ArrowLeft />
                            </IconButton>
                            <IconButton>
                                <ArrowRight />
                            </IconButton>
                        </div>
                    </div> */}
                    <Carousel />
                </div>

                <div className="flex justify-center py-6">
                    <h1 className='text-2xl'>Our Trusted Companies</h1>
                </div>
                <div className="grid grid-cols-5 px-6 py-2 gap-12">
                    <div className="w-full h-[100px] bg-blue-950 rounded-md">Example 1</div>
                    <div className="w-full h-[100px] bg-blue-950 rounded-md">Example 1</div>
                    <div className="w-full h-[100px] bg-blue-950 rounded-md">Example 1</div>
                    <div className="w-full h-[100px] bg-blue-950 rounded-md">Example 1</div>
                    <div className="w-full h-[100px] bg-blue-950 rounded-md">Example 1</div>
                </div>


                <div id="contact" className='grid grid-cols-2 gap-8 px-4 pt-24 py-12 text-lg items-start justify-center'>
                    <div className="content flex flex-col">
                        <h1 className='font-bold mb-4 text-7xl pt-4 opacity-35'>Kenapa Memilih Kami</h1>
                        <p>
                            Get to know us! We are dedicated to revolutionizing education with our cutting-edge technology and innovative solutions. Our mission is to streamline school management and enhance learning experiences through digital advancements. Discover our story and the passion driving our commitment to transforming education.
                        </p>
                    </div>
                    <div className="w-full flex items-center justify-center">
                        <img id='image' src={product3} alt='product3' className="w-3/4" />
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className={`bg-slate-200 w-full h-fit px-16 py-6 ${isDarkMode ? 'bg-slate-800 text-slate-100' : ''}`}>
                <div className="grid grid-cols-5 gap-12 text-lg font-medium">
                    <div className="w-full h-auto py-6">
                        <div className="flex items-center gap-6 col-span-2">
                            <img src={Logo} alt="black logo" className="size-[60px]" />
                            <img src={TextLogo} alt="black logo" className="size-[120px]" />
                        </div>
                        <h1>Edunex Space</h1>
                        <p>Jl. Unknown No. xx Banjarmasin, Kalimantan Selatan, 747521</p><br />
                        <ul>
                            <li>
                                <PhoneAndroid /> 082267678989
                            </li><br />
                            <li>
                                <Mail /> misterx@edunex.com
                            </li><br />
                            <li>
                                <div className="flex gap-6 w-full">
                                    <Facebook />
                                    <Instagram />
                                    <Twitter />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full h-auto flex flex-col gap-6 col-span-2 col-start-3 pt-[144px]">
                        <p>Blog</p>
                        <p>Contact Us</p>
                        <p>Privacy Policy</p>
                        <p>Terms</p>
                    </div>
                </div>
            </div>
            <div className="w-full flex h-auto px-12 py-4 items-center gap-2">
                &copy; <p>2024 Edunex | sebagian teks hilang ....</p>
            </div>
        </div>
    );
};

export default LandingPage;
