import React, { useState, useEffect } from 'react';
import Logo from '../../assets/logo_short.svg';
import TextLogo from '../../assets/Group 10.svg';
import home from '../../assets/main.jpg';
import product1 from '../../assets/product1.jpg';
import './style.css';
import { DarkMode, Facebook, Instagram, Mail, PhoneAndroid, Twitter, WbSunny } from '@mui/icons-material';
// import { IconButton } from '@mui/material';
import Carousel from '../../components/molecules/Carousel';
import Navbar from '../../components/organisms/NavigasiBar';
import TimelineComponenet from '../../components/molecules/Timeline';
import WaveTop from '../../components/molecules/WaveTop';
import WaveBottom from '../../components/molecules/WaveBottom';
import InfoPanelLeft from '../../components/organisms/InfoPanelLeft';




const LandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])


  useEffect(() => {
    const handleScroll = () => {
      const sections = ['beranda', 'layanan', 'program', 'kontak', 'sponsored', 'our'];
      sections.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          element.classList.toggle('visible', rect.top < window.innerHeight && rect.bottom > 0);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementTop - 275, behavior: 'smooth' });
    }
  };

  return (
    <div className={`Header w-full h-full overflow-x-hidden ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="fixed w-auto px-6 items-center justify-end z-[100] right-0 bottom-6">
        <button onClick={toggleDarkMode} className="toggle-button flex items-center justify-center w-[50px] h-[50px]">
          {isDarkMode ? <WbSunny /> : <DarkMode />}
        </button>
      </div>
      {/* HEADER + NAVIGATION */}
      <Navbar
        isDarkMode={isDarkMode}
        scrollToSection={scrollToSection}
      />

      {/* HEAD CONTENT */}
      <div className="flex flex-col text-left">
        <div
          id="beranda"
          className={`grid gap-8 px-4 pt-8 py-12 text-lg items-start justify-center w-full ${isMobile ? 'grid-cols-1' : 'grid-cols-5'}`}
        >
          <div className={`content w-full flex flex-col ${isMobile ? 'col-span-1' : 'col-span-2'} gap-6 place-content-start`}>
            <div className={`font-bold mb-4 pt-4 opacity-35 text-left ${isMobile ? 'text-6xl' : 'text-8xl'}`}>
              Apa itu <p className="underline">Edunex</p>?
            </div>
            <h1 className="text-left font-bold text-2xl">
              Transformasi digital di pendidikan kini lebih mudah dari sebelumnya!
            </h1>
            <p className="text-left">
              Dengan sistem absensi online kami, catat dan lacak kehadiran siswa tanpa kerumitan. Ditambah dengan platform pelajaran online kami yang dirancang untuk interaktivitas dan keterlibatan siswa, Anda dapat memodernisasi cara Anda mengelola sekolah dan mengajarkan materi pelajaran. Ciptakan lingkungan belajar yang lebih adaptif dan efisien hari ini!
            </p>
          </div>

          {!isMobile && (
            <div className="w-full h-full col-span-3 flex items-center justify-center">
              <img id="image" src={home} alt="homepage" className="w-1/2" />
            </div>
          )}

          {isMobile && (
            <div className="w-full h-full flex items-center justify-center col-span-1">
              <img id="image" src={home} alt="homepage" className="w-1/2" />
            </div>
          )}
        </div>

        {/* BODY CONTENT */}

        <InfoPanelLeft
          isDarkMode={isDarkMode}
          srcImg={product1}
          title={'Layanan'}
          id={"layanan"}
          heading={"Revolusi Administrasi Sekolah: Solusi Kehadiran Online & Pembelajaran Interaktif Terbaik"}
          context={"Revolusikan administrasi sekolah Anda dengan platform kehadiran online dan pembelajaran interaktif kami yang canggih. Lacak kehadiran dengan gampang dan buat siswa lebih terlibat seperti belum pernah sebelumnya. Transformasikan pendidikan secara efisien dengan solusi inovatif kami!"}
        />
        <div className="flex justify-center pt-12">
          <h1 className="font-bold mb-4 text-8xl pt-4 opacity-35">Program</h1>
        </div>
        <div
          id="program"
          className="section content flex gap-8 px-4 py-12 text-lg items-start justify-center"
        >
          <Carousel isDarkMode={isDarkMode} isMobile={isMobile}/>
        </div>

        <WaveTop
          classNamePath={`fill-current ${isDarkMode ? "text-slate-800" : "text-slate-200"
            }`}
        />
        <div
          className={`w-full h-fit pb-24 flex flex-col ${isDarkMode ? "bg-slate-800" : "bg-slate-200"
            } justify-center`}
        >
          <div id="sponsored" className="section">
            <div className="flex justify-center py-6">
              <h1 className="text-2xl">Our Trusted Companies</h1>
            </div>
            <div className="grid grid-cols-5 px-6 py-2 gap-12">
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 1
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 2
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 3
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 4
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 5
              </div>
            </div>
          </div>
        </div>
        <WaveBottom
          classNamePath={`fill-current ${isDarkMode ? "text-slate-800" : "text-slate-200"
            }`}
        />

<div
  id="our"
  className={`section grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-8 px-4 pt-12 py-12 text-lg items-start justify-center`}
>
  <div className="content flex flex-col order-1">
    <h1 className={`font-bold mb-4 ${isMobile ? 'text-5xl' : 'text-7xl'} pt-4 opacity-35 text-left`}>
      Kenapa Memilih Kami
    </h1>
    <h1 className="text-left font-bold text-2xl pb-2">
      <span className="text-3xl font-bold">Kenali kami!</span> Kami
      berkomitmen untuk merevolusi pendidikan dengan teknologi canggih
      dan solusi inovatif.
    </h1>
    <p>
      Misi kami adalah mempermudah pengelolaan sekolah dan meningkatkan
      pengalaman belajar melalui kemajuan digital. Temukan cerita kami
      dan semangat yang mendorong kami untuk mengubah dunia pendidikan.
    </p>
  </div>
  
  {/* Timeline component: Diurutkan di bawah konten pada mobile */}
  <div className={`w-full flex ${isMobile ? 'order-2 mt-6' : ''}`}>
    <TimelineComponenet isDarkMode={isDarkMode} />
  </div>
</div>

      </div>

      {/* <CarouselSlide /> */}
      {/* FOOTER */}
      <div
        className={`bg-slate-200 w-full h-fit px-16 py-6 ${isDarkMode ? "bg-slate-800 text-slate-100" : ""
          }`}
      >
        <div className="grid grid-cols-5 gap-12 text-lg font-medium">
          <div id="kontak" className="w-full h-auto py-6">
            <h1>Edunex Space</h1>
            <p>Jl. Unknown No. xx Banjarmasin, Kalimantan Selatan, 747521</p>
            <br />
            <ul>
              <li>
                <PhoneAndroid /> 082267678989
              </li>
              <br />
              <li>
                <Mail /> misterx@edunex.com
              </li>
              <br />
              <li>
                <div className="flex gap-6 w-full">
                  <Facebook />
                  <Instagram />
                  <Twitter />
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full h-auto flex flex-col gap-6 col-span-2 col-start-3 pt-[144px]">
            <p>Blog</p>
            <p>Contact Us</p>
            <p>Privacy Policy</p>
            <p>Terms</p>
          </div>
          <div className="relative flex flex-col items-center gap-[-10px] pt-[100px]">
            {/* Logo on top */}
            <img src={Logo} alt="black logo" className="size-[150px] z-10" />
            {/* Text logo below with a negative margin to overlap */}
            <img
              src={TextLogo}
              alt="black logo"
              className="size-[130px] z-0"
              style={{ marginTop: "-20px" }}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex h-auto px-12 py-4 items-center gap-2">
        &copy; <p>2024 Edunex | sebagian teks hilang ....</p>
      </div>
    </div>
  );
};

export default LandingPage;
